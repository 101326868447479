import React from 'react';
import './landingpage.css';

const LandingPage = () =>{
    return(
        <>
            <div className = 'container' id = 'Home'>
                <h1>Ryan Lee</h1>
                <hr/>
                <h2>I like to build things.</h2>
            </div>
        </>
    );
};


export default LandingPage;